.fullmint {
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.fullmint .main-interactive-container {
  margin: 50px 0;
  width: 100%;
  text-align: center;
}

.fullmint .textblock {
  text-align: left;
}



/* .fullmint div {
  margin: 100px 0;
} */

.fullmint h2 {
  margin-top: 100px;
  margin-bottom: 50px;
}

.fullmint .token-selector {
  margin: 5px;
}

.fullmint .token-selector-container {
  margin: 5px;
  display: flex;
  flex-direction: column;
}

.fullmint .mint-button-container {
  margin: 40px;
}

.fullmint .token-selectors {
  margin: 40px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}

.fullmint select,
.fullmint option {
  font-size: 1rem;
}
.contest {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: left;
  width: 100%;
}

.contest .gallery {
  width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}

.contest .entry img {
}

.contest .entry-info {
  text-align: center;
  margin: 15px;
}

.contest .entry-name {
  font-weight: 500;
  font-size: 1.1rem;
  margin: 5px;
}

.contest .size-button-box {
  margin: 30px;
}
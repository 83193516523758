.home {
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.home .social-media-links {
  margin-top: 1rem;
  margin-bottom: -4rem;
}

.home .social-media-links > span {
  display:inline-block;
}

.home .social-media-links span {
  margin: 10px;
}

.home .goto-container {
  margin: 2rem 0;
}

.home .goto-container .goto-button {
  padding: 1rem;
  margin: 20px;
  min-width: 100px;
}

.home .cover-img {
  width: 720px;
  margin-top: -60px;
  margin-bottom: -60px;
}

@media only screen and (max-width: 768px) {
  .home .cover-img {
    width: 95vmin;
    margin-top: -5vmin;
    margin-bottom: -15vmin;
  }
}

.lorenzSystem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.lorenzProjection {
  -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
  filter: invert(100%);
  width: 100%;
}

.projectionStack {
  margin: 20px 0px;
}


.registration-link {
  display: none;
}
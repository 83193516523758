.details {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: left;
  margin-top: 3rem;
}

.details .lorenz-example {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.details .lorenz-example > img{
  max-width: 370px;
}

.details .border {
  border: 1px solid white;
}


.details .lorenz-system {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px;
}

.details .lorenz-system > span {
  display: block !important;
  margin: 5px;
}

@media only screen and (max-width: 768px) {
  .details .lorenz-system  {
    flex-direction: column;
  }
}


.details .image-container {
  display: flex;
  justify-content: space-around;
  margin: 0.75rem 0;
  flex-wrap: wrap;
}

.details .image-container > img {
  max-width: 370px;
  margin: 0.75rem 0px;
}

.details .systems-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 768px) {
  .details .systems-grid {
    grid-template-columns: 1fr;
  }
}

.details .systems-grid > div {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.details .systems-grid > div > div {
  margin: 10px 0px;
}

.details .systems-grid  h4 {
  margin: 10px;
}

.details .systems-grid img {
  width: 100%;
  border: 1px solid white;
}

.details .system-equations {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
  /* align-items: center; */
  align-content: space-between;
  justify-content: space-around;
}

.details .eq-container-inner {
  display: flex;
  flex-direction: column;
  text-align: left !important;
  margin: 0px 20px;
}

.details .eq-container-inner > span {
  display: block !important;
  margin: 5px;
}

.details .MathJax {
  font-size: 1.1em !important;
}

.details .address {
}

.details .address::before {
  content: "Contract: ";
}


.details #memory-sketch {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.details #memory-sketch > div {
  flex: 1 1 auto;
  display: inline-flex;
  flex-direction: column;
}

.details #memory-sketch > div div{
  display: inline-block;
}


.details #memory-sketch > div > div:first-child{
  border: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 5px 0px;
}

.details #memory-sketch > div > div:last-child{
  padding-left: 5px;
}

.details .inner-array > div:first-child {
  background-color: #440000;
}

.details .rk4-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin: 5px 0;
}

.details .rk4-container > span {
  flex: 1 1 auto;
  margin: 5px;
  text-align: center;
}
.about {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: left;
}

.about .gallery {
  width: 100vw;
  max-width: 1200px;
  margin-top: 50px;
}

.about .gallery img {
  width: 100%;
}

.about .elevator {
  font-weight: 500;
}

.about .centered {
  text-align: center;
}

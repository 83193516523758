.tools {
  flex: 1 1 auto;
  max-width: 1000px;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-number {
  font-family: "DMMono";
  background-color: black;
  color: white;
  border: 1px solid #aaaaaa;
  width: 50px;
  text-align: right;
}

/* Chrome, Safari, Edge, Opera */
.slider-number::-webkit-outer-spin-button,
.slider-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.slider-number[type="number"] {
  -moz-appearance: textfield;
}

.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  flex-grow: 1;
  margin: 2px;
  /* width: 100%; Full-width */
  height: 10px; /* Specified height */
  background: #444444; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 15px; /* Set a specific slider handle width */
  height: 15px; /* Slider handle height */
  background: white; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0px 0px 0px 0px;
  width: 15px; /* Set a specific slider handle width */
  height: 15px; /* Slider handle height */
  background: white; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

input[type="button"] {
  /* font-weight: 500; */
  font-size: 1.15em;
}

.tools .button-box input[type="button"] {
  /* font-weight: 500; */
  font-size: 1.15em;
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 0;
}

.invert {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.size-slider-box {
  display: flex;
  align-items: center;
  padding: 10px;
}

.size-slider-box .labeled-slider {
  flex-grow: 1;
}

.paletteTitle {
  font-weight: 500;
  font-size: 1.5rem;
  margin: 30px;
}

.border {
  border: 1px solid white;
}

.projectionCustomizer {
  /* margin-top: 50px; */
}

.projectionCustomizer-tools {
  display: flex;
  justify-items: center;
}

.projectionSliders {
  padding: 10px;
  flex-grow: 1;
}

.labeled-slider {
  display: flex;
}

.labeled-slider .slider-label {
  display: inline-block;
  width: 75px;
  text-align: end;
  margin-right: 10px;
}

.labeled-slider .slider-slider {
  display: inline-block;
  flex-grow: 1;
}

.projectionExpert {
  flex-grow: 1;
  padding: 10px;
  padding-top: 0px;
}

.projectionExpert input[type="number"] {
  margin: 5px;
  width: 70px;
}

.array-input-label {
  display: inline-block;
  /* margin: 0px 20px; */
  width: 100px;
}

.array-column-label {
  display: inline-block;
  /* margin: 0px 20px; */
  width: 76px;
  margin: 5px;
}

.tools .wallet-connect {
  display: inline-block;
  margin: 15px 0px;
}

.tools .token-selector {
  font-size: 1.15rem;
}

.tools .token-selector > div{
  height: 100%;
}

.tools .token-selector select {
  font-size: 1rem;
}

.tools .button-box {
  height: 3rem;
  display: flex;
}

.tools .button-box div {
  border: 1px solid white;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.tools .tools-container {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
}

.tools-container .left {
  flex: 1;
}

.tools-container .right {
  flex: 1;
  display: flex;
  flex-flow: column;
}

.colormap {
  flex-grow: 1;
}

.disabled {
  opacity: 0.2;
}

.mainnet-message {
  margin-top: 3rem;
  margin-bottom: 4rem;
}

.explanation-text {
  text-align: left;
}

.explanation-text a {
  text-decoration: underline;
}

.tools .title > h2{
  margin: 10px;
}
.tools .title > h4{
  margin: 10px;
}
.tools .title {
  margin: 50px 0px 30px;
}



.explanation-text > ul {
  padding-inline-start: 0;
  padding-inline-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin: 20px;

  columns: 2;
  column-gap: 40px;
  column-rule:2px solid white;
}

.explanation-text > ul > li{
  list-style-type: none;
  padding: 0;
}


@media only screen and (max-width: 1000px) {
  .tools .tools-container {
    flex-direction: column;
  }

  .explanation-text > ul {
    columns: 1;
  }
}
.svg-window img {
    width: 100%;
    height: auto;
  }

.svg-window {
  background-color: black;
  aspect-ratio: 1;
  border: 1px solid white;
  position: relative;
}

.svg-window .rendering-indicator {
  z-index: 10;
  position: absolute;
  margin: 2rem;
  height: 1rem;
}

.svg-window .annotation {
  z-index: 10;
  position: absolute;
  right: 3%;
  bottom: 3%;
}